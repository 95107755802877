@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  svg.feather {
    stroke-width: 1.5 !important;
  }
  a {
    border-color: white;
  }
}
@layer utilities {
  .flex-2 {
    flex: 2;
  }
  .flex-3 {
    flex: 3;
  }
  .flex-4 {
    flex: 4;
  }
  .collapse {
    visibility: inherit;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL2hvbWUvdnN0cy93b3JrLzEvcy9mcm9udGVuZC9zcmMvdGFpbHdpbmQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtBQUNBO0FBQ0E7QUFFQTtFQUNFO0lBQ0U7O0VBRUY7SUFDRTs7O0FBSUo7RUFDRTtJQUNFOztFQUVGO0lBQ0U7O0VBRUY7SUFDRTs7RUFFRjtJQUNFIiwic291cmNlc0NvbnRlbnQiOlsiQHRhaWx3aW5kIGJhc2U7XG5AdGFpbHdpbmQgY29tcG9uZW50cztcbkB0YWlsd2luZCB1dGlsaXRpZXM7XG5cbkBsYXllciBiYXNlIHtcbiAgc3ZnLmZlYXRoZXIge1xuICAgIHN0cm9rZS13aWR0aDogMS41ICFpbXBvcnRhbnQ7XG4gIH1cbiAgYSB7XG4gICAgYm9yZGVyLWNvbG9yOiB3aGl0ZTtcbiAgfVxufVxuXG5AbGF5ZXIgdXRpbGl0aWVzIHtcbiAgLmZsZXgtMiB7XG4gICAgZmxleDogMjtcbiAgfVxuICAuZmxleC0zIHtcbiAgICBmbGV4OiAzO1xuICB9XG4gIC5mbGV4LTQge1xuICAgIGZsZXg6IDQ7XG4gIH1cbiAgLmNvbGxhcHNlIHtcbiAgICB2aXNpYmlsaXR5OiBpbmhlcml0O1xuICB9XG59XG4iXX0= */